import './Text-carrousel.scss';

function TextCarrousel() {
  return (
    <div className="m-scroll">
      <div className="m-scroll__title">
        <div>
          <h1 className="font-h1">TRABAJAMOS PARA HACER TU VIDA MÁS FÁCIL.</h1>
          <h1 className="font-h1">TRABAJAMOS PARA HACER TU VIDA MÁS FÁCIL.</h1>
        </div>
      </div>
    </div>
  );
}
export default TextCarrousel;
