import './Gravity.scss';
import { useEffect } from 'react';
import Matter from 'matter-js';

function Gravity() {
  useEffect(() => {
    manoverboardJumble();
  });
  return (
    <div id="berry-letters">
      <div id="berry-letters-wrap">
        <div id="berry-letters-gravity"></div>
      </div>
    </div>
  );
}

export default Gravity;

let isRendered = false;
function manoverboardJumble() {
  if (!isRendered) {
    isRendered = true;
    var engine = Matter.Engine.create();
    var worldEngine = engine.world;
    var v = Matter.Runner.create();
    var container = document.getElementById('berry-letters-gravity');
    var { offsetHeight: height, offsetWidth: width } = container;
    /*var sizeMultiplier = 1;*/
    var matterContainer = Matter.Render.create({
      element: container,
      engine: engine,
      options: { width: width, height: height, wireframes: false, background: 'transparent' }
    });

    init();
    window.addEventListener('resize', () => {
      Matter.World.clear(worldEngine);
      configureVariables();
      matterContainer.bounds.max.x = width;
      matterContainer.bounds.max.y = height;
      matterContainer.options.width = width;
      matterContainer.options.height = height;
      matterContainer.canvas.width = width;
      matterContainer.canvas.height = height;
      addFigures();
    });
  }
  function configureVariables() {
    container = document.getElementById('berry-letters-gravity');
    ({ offsetHeight: height, offsetWidth: width } = container);
  }

  function addFigures() {
    var w = 10;
    var options = { isStatic: true, render: { fillStyle: 'transparent' } };
    let text = 'berrylabberrylab';
    let numberOfIcons = 2;
    let totalAreaToFill = height * width * 2;
    let totalElements = text.length + numberOfIcons;
    let widthPerItem = Math.sqrt(totalAreaToFill / totalElements) * 0.4;
    let heightPerItem = Math.sqrt(totalAreaToFill / totalElements) * 0.6;
    let baseSpriteScale = 2;
    let spriteScale = 2;
    let proportionToFill = (height + width) / 2;
    if (proportionToFill > 1600) {
      spriteScale = baseSpriteScale * 1;
    } else if (proportionToFill > 1200 && proportionToFill <= 1600) {
      spriteScale = baseSpriteScale * 0.8;
    } else if (proportionToFill > 900 && proportionToFill <= 1200) {
      spriteScale = baseSpriteScale * 0.65;
    } else if (proportionToFill > 600 && proportionToFill <= 900) {
      spriteScale = baseSpriteScale * 0.45;
    } else if (proportionToFill > 430 && proportionToFill <= 600) {
      spriteScale = baseSpriteScale * 0.2;
    } else {
      spriteScale = baseSpriteScale * 0.1;
    }

    let figures = [...text].map((letter) => {
      return Matter.Bodies.rectangle(
        Math.random() * width,
        Math.random() * height,
        widthPerItem,
        heightPerItem,
        {
          angle: Math.PI * Math.random(),
          render: {
            sprite: {
              texture: `./images/gravity/${letter}.svg`,
              xScale: spriteScale,
              yScale: spriteScale
            }
          }
        }
      );
    });

    let logos = Array.from({ length: numberOfIcons }).map(() => {
      return Matter.Bodies.circle(
        Math.random() * height,
        Math.random() * height,
        heightPerItem / 2,
        {
          angle: Math.PI * Math.random(),
          render: {
            sprite: {
              texture: './images/gravity/logo.svg',
              xScale: spriteScale,
              yScale: spriteScale
            }
          }
        }
      );
    });

    Matter.World.add(worldEngine, [
      Matter.Bodies.rectangle(width / 2, -w, width + 2 * w, 50.5, options),
      Matter.Bodies.rectangle(width / 2, height + w, width + 2 * w, 50.5, options),
      Matter.Bodies.rectangle(width + w, height / 2, 50.5, height + 2 * w, options),
      Matter.Bodies.rectangle(-w, height / 2, 50.5, height + 2 * w, options),
      ...figures,
      ...logos
    ]);

    var mouseInCanvas = Matter.Mouse.create(matterContainer.canvas);
    var mouseConstraintEngine = Matter.MouseConstraint.create(engine, {
      mouse: mouseInCanvas,
      constraint: { stiffness: 0.2, render: { visible: !1 } }
    });
    Matter.World.add(worldEngine, mouseConstraintEngine);
    matterContainer.mouse = mouseInCanvas;
    mouseConstraintEngine.mouse.element.removeEventListener(
      'mousewheel',
      mouseConstraintEngine.mouse.mousewheel
    );
    mouseConstraintEngine.mouse.element.removeEventListener(
      'DOMMouseScroll',
      mouseConstraintEngine.mouse.mousewheel
    );
    Matter.Render.lookAt(matterContainer, {
      min: {
        x: 0,
        y: 0
      },
      max: { x: width, y: height }
    });
  }

  function changePointOfGravity() {
    engine.world.gravity.y = (Math.round(Math.random()) * 2 - 1) * 0.25;
    engine.world.gravity.x = (Math.round(Math.random()) * 2 - 1) * 0.25;
  }

  function init() {
    configureVariables();
    Matter.Render.run(matterContainer);
    Matter.Runner.run(v, engine);
    worldEngine.bodies = [];
    addFigures();
    setInterval(changePointOfGravity, 800);
  }
}
