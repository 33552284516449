import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// After
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LceLasiAAAAACjnWJvLjxPPIah2XLUlarHRzLl1"
    language="[optional_language]"
  >
    <App />
  </GoogleReCaptchaProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
