import './Footer-cont.scss';

// eslint-disable-next-line react/prop-types
function FooterCont({ className }) {
  return (
    <div className={className + ' footer-info'}>
      <div>
        <p className="new-title-3 mobile">ESCRÍBENOS PARA CONTARTE MÁS ACERCA DE NOSOTROS</p>
      </div>
      <div>
        <p className="new-title-4 desktop">
          ESTAMOS MUY INTERESADOS EN TRABAJAR PARA TI, CUÉNTANOS TUS PROYECTOS.
        </p>
      </div>
    </div>
  );
}

export default FooterCont;
