import './Home-mobile.scss';

function HomeMobile({ className }) {
  return (
    <div className={className + ' home-new-mobile'}>
      <div>
        <p className="new-title-2">PENSAMOS, CREAMOS E INNOVAMOS PARA TI.</p>
      </div>
    </div>
  );
}

export default HomeMobile;
