import './Contacto.scss';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function Contacto() {
  let [formState, setFormState] = useState({});
  let [showError, setShowError] = useState(false);
  let [showSend, setShowSend] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let { executeRecaptcha } = useGoogleReCaptcha();

  function handleChange(fieldName, value) {
    setFormState({ ...formState, [fieldName]: value });
  }
  function showModal() {
    setShowSend(true);
  }

  function closeModal() {
    setShowSend(false);
  }

  const sendForm = async (e) => {
    e.preventDefault();
    if (!formState.nombre || !formState.telefono || !formState.email) {
      setShowError(true);
    } else if (!validator.isEmail(formState.email)) {
      setShowError(true);
      setEmailError(true);
    } else {
      const token = await executeRecaptcha('setForm');
      await axios
        .post(`${process.env.REACT_APP_API_URL}/formulario`, { ...formState, token: token })
        .then((response) => {
          setFormState({ nombre: '', telefono: '', email: '', mensaje: '' });
          setShowError(false);
          setEmailError(false);
          showModal();
        })
        .catch((error) => {
          alert('error');
        });
    }
  };

  return (
    <div className="contact-grid" id="contact">
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
        <Grid item xs={12} lg={4}>
          <div className="contact-column">
            <div className="title-contact">CONTACTO</div>
            <div className="phone-div phone-container">
              <div>
                <FontAwesomeIcon icon={faPhone} className="phone-icon" />
              </div>
              <div>
                <div className="numbers-info">+(57) 3015653747 </div>
                <div className="numbers-info">+(57) 3152639800</div>
              </div>
            </div>
            <div className="phone-div">
              <div>
                <FontAwesomeIcon icon={faEnvelope} className="phone-icon" />
              </div>
              <div className="info-contact">info@berrylab.dev</div>
            </div>
            <div className="phone-div">
              <div>
                <FontAwesomeIcon icon={faLocationDot} className="phone-icon" />
              </div>
              <div>
                <div className="info-contact">Calle 57 # 18 - 22</div>
                <div className="info-contact">Oficina 202</div>
                <div className="info-contact">Bogotá - Colombia</div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className="contact-column">
            <div className="title-contact">ESCRÍBENOS</div>
            <form autoComplete="off">
              <div>
                <input
                  autoComplete="false"
                  type="text"
                  className="feedback-input"
                  placeholder="Nombre"
                  value={formState.nombre}
                  onChange={(event) => {
                    handleChange('nombre', event.target.value);
                  }}
                />
                {showError && !formState.nombre && (
                  <span className="field-text-1">Esta información es obligatoria</span>
                )}
              </div>
              <div>
                <input
                  autoComplete="false"
                  type="text"
                  className="feedback-input"
                  placeholder="Teléfono"
                  value={formState.telefono}
                  onChange={(event) => {
                    handleChange('telefono', event.target.value);
                  }}
                />
                {showError && !formState.telefono && (
                  <span className="field-text-1">Esta información es obligatoria</span>
                )}
              </div>
              <div>
                <input
                  autoComplete="false"
                  type="text"
                  className="feedback-input"
                  placeholder="Email"
                  value={formState.email}
                  onChange={(event) => {
                    handleChange('email', event.target.value);
                  }}
                />
                {showError && !formState.email && (
                  <span className="field-text-1">Esta información es obligatoria</span>
                )}

                {showError && formState.email && emailError && (
                  <span className="field-text-1">Ingresa un correo válido</span>
                )}
              </div>
              <textarea
                name="text"
                className="feedback-input"
                placeholder="Mensaje"
                onChange={(event) => {
                  handleChange('mensaje', event.target.value);
                }}
                value={formState.mensaje}
              ></textarea>
              <input type="submit" value="ENVIAR" onClick={sendForm} />
            </form>
          </div>
          <Modal show={showSend} onHide={closeModal}>
            <Modal.Header closeButton className="modal-form">
              <Modal.Title>MUCHAS GRACIAS POR ESCRIBIRNOS</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-form-1">
              Tu mensaje ha sido enviado correctamente.
            </Modal.Body>
            <Modal.Footer className="modal-form">
              <Button variant="secondary" onClick={closeModal} className="modal-close">
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className="title-contact">SÍGUENOS</div>
          <div className="red-div">
            <a href="#f">
              <FontAwesomeIcon icon={faFacebook} className="red-icon" />
            </a>
            <a href="#f">
              <FontAwesomeIcon icon={faInstagram} className="red-icon" />
            </a>
            <a href="#f">
              <FontAwesomeIcon icon={faWhatsapp} className="red-icon" />
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Contacto;
