import './Text-effect.scss';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

function TextEffect() {
  function reveal() {
    var reveals = document.querySelectorAll('.reveal');
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 100;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', reveal);
    reveal();
  }, []);
  return <></>;
}

Text.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export default TextEffect;
