import './Home-new.scss';
import people from '../assets/imagen/people.jpg';
import axios from 'axios';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import peopleWepb from '../assets/imagen/people.webp';
import ImageWebp from '../shared/image-webp/ImageWepb';

function HomeNew({ className }) {
  let [formState, setFormState] = useState({});
  let [showError, setShowError] = useState(false);
  let [showSend, setShowSend] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let { executeRecaptcha } = useGoogleReCaptcha();

  function handleChange(fieldName, value) {
    setFormState({ ...formState, [fieldName]: value });
  }

  function showModal() {
    setShowSend(true);
  }

  function closeModal() {
    setShowSend(false);
  }

  const sendForm = async (e) => {
    e.preventDefault();
    if (!formState.nombre || !formState.telefono || !formState.email) {
      setShowError(true);
    } else if (!validator.isEmail(formState.email)) {
      setShowError(true);
      setEmailError(true);
    } else {
      const token = await executeRecaptcha('setForm');

      await axios
        .post(`${process.env.REACT_APP_API_URL}/formulario`, { ...formState, token: token })
        .then((response) => {
          setFormState({ nombre: '', telefono: '', email: '', mensaje: '' });
          setShowError(false);
          setEmailError(false);
          showModal();
        })
        .catch((error) => {
          alert('error');
        });
    }
  };
  return (
    <div className={className + ' home-new'} id="home">
      <div>
        <p className="new-title desktop">PENSAMOS, CREAMOS E INNOVAMOS PARA TI.</p>
      </div>
      <div className="line-home mobile">
        <hr className="line-menu" />
      </div>
      <div>
        <p className="new-title-mobile mobile">
          ESTAMOS MUY INTERESADOS EN TRABAJAR PARA TI, CUÉNTANOS TUS PROYECTOS.
        </p>
      </div>
      <div className="new-pic-container mobile">
        <ImageWebp src={people} srcWebp={peopleWepb} className="new-pic" />
      </div>
      <div className="box-form">
        <div className="new-pic-container">
          <ImageWebp src={people} srcWebp={peopleWepb} className="new-pic desktop" />
        </div>
        <div className="form-1">
          <form className="form-new" id="form-new">
            <div className="position-relative">
              <input
                placeholder="Nombre"
                className="input-box"
                value={formState.nombre}
                onChange={(event) => {
                  handleChange('nombre', event.target.value);
                }}
              />
              {showError && !formState.nombre && (
                <span className="field-text">Esta información es obligatoria</span>
              )}
            </div>
            <div className="position-relative">
              <input
                placeholder="Teléfono"
                className="input-box"
                value={formState.telefono}
                onChange={(event) => {
                  handleChange('telefono', event.target.value);
                }}
              />
              {showError && !formState.telefono && (
                <span className="field-text">Esta información es obligatoria</span>
              )}
            </div>
            <div className="position-relative">
              <input
                placeholder="Email"
                value={formState.email}
                className="input-box"
                onChange={(event) => {
                  handleChange('email', event.target.value);
                }}
              />
              {showError && !formState.email && (
                <span className="field-text">Esta información es obligatoria</span>
              )}
              {showError && formState.email && emailError && (
                <span className="field-text-1">Ingresa un correo válido</span>
              )}
            </div>
            <textarea
              placeholder="Mensaje"
              className="input-box"
              value={formState.mensaje}
              onChange={(event) => {
                handleChange('mensaje', event.target.value);
              }}
            ></textarea>
          </form>
          <input
            className="button-form"
            type="submit"
            value="ENVIAR"
            onClick={sendForm}
            id="form-new-button"
          />
        </div>
      </div>
      <Modal show={showSend} onHide={closeModal}>
        <Modal.Header closeButton className="modal-form">
          <Modal.Title>MUCHAS GRACIAS POR ESCRIBIRNOS</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-form-1">Tu mensaje ha sido enviado correctamente.</Modal.Body>
        <Modal.Footer className="modal-form">
          <Button variant="secondary" onClick={closeModal} className="modal-close">
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HomeNew;
