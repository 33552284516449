import './Slider.scss';
import f1 from '../../../assets/imagen/logos/angular.png';
import f1Webp from '../../../assets/imagen/logos/angular.webp';
import f2 from '../../../assets/imagen/logos/c.png';
import f2Webp from '../../../assets/imagen/logos/c.webp';
import f3 from '../../../assets/imagen/logos/css.png';
import f3Webp from '../../../assets/imagen/logos/css.webp';
import f4 from '../../../assets/imagen/logos/docker.png';
import f4Webp from '../../../assets/imagen/logos/docker.webp';
import f5 from '../../../assets/imagen/logos/git.png';
import f5Webp from '../../../assets/imagen/logos/git.webp';
import f6 from '../../../assets/imagen/logos/html.png';
import f6Webp from '../../../assets/imagen/logos/html.webp';
import f7 from '../../../assets/imagen/logos/jenkins.png';
import f7Webp from '../../../assets/imagen/logos/jenkins.webp';
import f8 from '../../../assets/imagen/logos/js.png';
import f8Webp from '../../../assets/imagen/logos/js.webp';
import f9 from '../../../assets/imagen/logos/mongo.png';
import f9Webp from '../../../assets/imagen/logos/mongo.webp';
import f11 from '../../../assets/imagen/logos/php.png';
import f11Webp from '../../../assets/imagen/logos/php.webp';
import f10 from '../../../assets/imagen/logos/mysql.png';
import f10Webp from '../../../assets/imagen/logos/mysql.webp';
import f12 from '../../../assets/imagen/logos/react.png';
import f12Webp from '../../../assets/imagen/logos/react.webp';
import f13 from '../../../assets/imagen/logos/sql.png';
import f13Webp from '../../../assets/imagen/logos/sql.webp';
import f14 from '../../../assets/imagen/logos/vue.png';
import f14Webp from '../../../assets/imagen/logos/vue.webp';
import ImageWebp from '../../../shared/image-webp/ImageWepb';

function Slider() {
  return (
    <div className="slider">
      <div className="slide-track">
        <div className="slide">
          <ImageWebp src={f1} srcWebp={f1Webp} className="img-1" />
        </div>
        <div className="slide">
          <ImageWebp src={f2} srcWebp={f2Webp} className="img-2" />
        </div>
        <div className="slide">
          <ImageWebp src={f3} srcWebp={f3Webp} className="img-3" />
        </div>
        <div className="slide">
          <ImageWebp src={f4} srcWebp={f4Webp} className="img-4" />
        </div>
        <div className="slide">
          <ImageWebp src={f5} srcWebp={f5Webp} className="img-5" />
        </div>
        <div className="slide">
          <ImageWebp src={f6} srcWebp={f6Webp} className="img-6" />
        </div>
        <div className="slide">
          <ImageWebp src={f7} srcWebp={f7Webp} className="img-7" />
        </div>
        <div className="slide">
          <ImageWebp src={f8} srcWebp={f8Webp} className="img-8" />
        </div>
        <div className="slide">
          <ImageWebp src={f9} srcWebp={f9Webp} className="img-9" />
        </div>
        <div className="slide">
          <ImageWebp src={f11} srcWebp={f11Webp} className="img-11" />
        </div>
        <div className="slide">
          <ImageWebp src={f10} srcWebp={f10Webp} className="img-10" />
        </div>
        <div className="slide">
          <ImageWebp src={f12} srcWebp={f12Webp} className="img-12" />
        </div>
        <div className="slide">
          <ImageWebp src={f13} srcWebp={f13Webp} className="img-13" />
        </div>
        <div className="slide">
          <ImageWebp src={f14} srcWebp={f14Webp} className="img-14" />
        </div>
        <div className="slide">
          <ImageWebp src={f1} srcWebp={f1Webp} className="img-1" />
        </div>
        <div className="slide">
          <ImageWebp src={f2} srcWebp={f2Webp} className="img-2" />
        </div>
        <div className="slide">
          <ImageWebp src={f3} srcWebp={f3Webp} className="img-3" />
        </div>
        <div className="slide">
          <ImageWebp src={f4} srcWebp={f4Webp} className="img-4" />
        </div>
        <div className="slide">
          <ImageWebp src={f5} srcWebp={f5Webp} className="img-5" />
        </div>
        <div className="slide">
          <ImageWebp src={f6} srcWebp={f6Webp} className="img-6" />
        </div>
        <div className="slide">
          <ImageWebp src={f7} srcWebp={f7Webp} className="img-7" />
        </div>
        <div className="slide">
          <ImageWebp src={f8} srcWebp={f8Webp} className="img-8" />
        </div>
        <div className="slide">
          <ImageWebp src={f9} srcWebp={f9Webp} className="img-9" />
        </div>
        <div className="slide">
          <ImageWebp src={f11} srcWebp={f11Webp} className="img-11" />
        </div>
        <div className="slide">
          <ImageWebp src={f10} srcWebp={f10Webp} className="img-10" />
        </div>
        <div className="slide">
          <ImageWebp src={f12} srcWebp={f12Webp} className="img-12" />
        </div>
        <div className="slide">
          <ImageWebp src={f13} srcWebp={f13Webp} className="img-13" />
        </div>
        <div className="slide">
          <ImageWebp src={f14} srcWebp={f14Webp} className="img-14" />
        </div>
      </div>
    </div>
  );
}

export default Slider;
