import Contacto from './contacto/Contacto';
import './Home.scss';
import Slider from './banner/Slider';
import HomeNew from '../../home/Home-new';
import WeNew from '../../we/We-new';
import What from '../../what/What';
import Services from '../../services/Services';
import DrawLogo from '../../shared/logodrawing/draw-logo';
import HomeMobile from '../../home/Home-mobile';
import FooterCont from './contacto/Footer-cont';
import TextCarrousel from './banner/Text-carrousel';

function Home() {
  return (
    <div className="home">
      <section>
        <HomeMobile className="mobile"></HomeMobile>
        <HomeNew className="desktop"></HomeNew>
        <WeNew></WeNew>
        <HomeNew className="mobile"></HomeNew>
      </section>
      <section>
        <TextCarrousel></TextCarrousel>
        <What></What>
        <DrawLogo></DrawLogo>
        <Services></Services>
      </section>
      <Slider></Slider>
      <FooterCont className="mobile"></FooterCont>
      <FooterCont className="desktop"></FooterCont>
      <Contacto />
    </div>
  );
}

export default Home;
