import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/shared-styles/reset.scss';
import './App.scss';
import Header from './layout/header/Header';
import { TimelineMax, TweenMax } from 'gsap';
import { ScrollMagicPluginGsap, ScrollMagicPluginIndicator } from 'scrollmagic-plugins';
import ScrollMagic from 'scrollmagic';
import Home from './sections/home/Home';
import Mouse from './shared/mouse/Mouse';
import Gravity from './sections/home/gravity/Gravity';
import Intro from './sections/intro/Intro';
import { useEffect, useState } from 'react';
import TextEffect from './shared/text-effect/Text-effect';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
ScrollMagicPluginIndicator(ScrollMagic);

function App() {
  let [, setIntroDone] = useState(true);
  const handleAfterIntro = () => {
    setIntroDone(true);
  };
  let [isPuppeteer, setIsPuppeteer] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const puppeteer = urlParams.get('puppeteer');
    setIsPuppeteer(!!puppeteer);
  }, []);

  return (
    <div>
      <TextEffect></TextEffect>
      {!isPuppeteer && <Intro handleAfterIntro={handleAfterIntro}></Intro>}
      {/*     <Maintenance></Maintenance>*/}
      <Header />
      <Home />
      <Mouse />
      <Gravity />
    </div>
  );
}

export default App;
