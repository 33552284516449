import './Intro.scss';
import { ReactComponent as BerryLogo } from '../../assets/imagen/logos/logofull.svg';
import { useEffect } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';

function Intro({ handleAfterIntro }) {
  useEffect(() => {
    var tl = gsap.timeline({
      defaults: { ease: 'power2.inOut' },
      delay: 1
    });
    const rects = document.querySelectorAll('#berry-logo rect');
    let shuffled = [...rects]
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    for (const rect of shuffled) {
      tl.to(rect, { fill: '#ff004f', duration: 0.8 }, '-=0.75');
    }

    const path = document.querySelector('#berry-logo path.cls-2');
    tl.fromTo(
      path,
      { 'stroke-dashoffset': 500, stroke: '#059b5c', duration: 1 },
      { 'stroke-dashoffset': 0, fill: '#059b5c', stroke: '#059b5c', duration: 1 },
      '-=1.0'
    );

    const pathText = document.querySelectorAll('#berry-logo path.cls-3');
    tl.fromTo(
      pathText,
      { 'stroke-dashoffset': 500, duration: 1, stroke: '#FFFFFF' },
      { 'stroke-dashoffset': 0, fill: 'white', stroke: '#FFFFFF', duration: 1 },
      '-=1.0'
    );

    const logoFull = document.querySelector('#berry-logo');
    tl.to(logoFull, { width: '230px', height: '80px', top: '10px', left: '30px' });

    const preloader = document.querySelector('#preloader-container');
    tl.to(preloader, { opacity: 0 });
    tl.then(() => {
      handleAfterIntro();
      preloader.remove();
    });
  }, []);
  return (
    <div id="preloader-container">
      <div id="berry-logo">
        <BerryLogo></BerryLogo>
      </div>
    </div>
  );
}

Intro.propTypes = {
  handleAfterIntro: PropTypes.func
};

export default Intro;
