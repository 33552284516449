import './draw-logo.scss';
import PropTypes from 'prop-types';
import { ReactComponent as Drawing } from './../../assets/imagen/logos/logo.svg';
import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function DrawLogo() {
  /*  function drawscroll() {
    var path = document.querySelector('#logobox svg path');
    var pathLength = path.getTotalLength();
    var box = document.querySelector('#logo-draw');
    var scrollPercentage = box.scrollTop / (box.scrollHeight - box.clientHeight);
    console.log(box.scrollTop, box.scrollHeight);
    var drawLength = pathLength * scrollPercentage;
    path.style.strokeDashoffset = pathLength - drawLength;
    if (scrollPercentage >= 0.99) {
      path.style.strokeDasharray = 'none';
      path.style.fill = '#139b5c';
    } else {
      path.style.strokeDasharray = pathLength + ' ' + pathLength;
      path.style.fill = 'none';
    }

    //rect

    var rects = document.querySelectorAll('#logobox svg rect');
    for (var rect of rects) {
      var rectLength = rect.getTotalLength();
      var rectDrawLength = rectLength * scrollPercentage;
      rect.style.strokeDashoffset = rectLength - rectDrawLength;
      if (scrollPercentage >= 0.99) {
        rect.style.strokeDasharray = 'none';
        rect.style.fill = '#ff004f';
      } else {
        rect.style.strokeDasharray = rectLength + ' ' + rectLength;
        rect.style.fill = 'none';
      }
    }
  }*/
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const puppeteer = urlParams.get('puppeteer');
    if (!puppeteer) {
      const timeline = gsap.timeline({
        scrollTrigger: { trigger: '#logo-draw', scrub: true, pin: true }
      });

      gsap.utils.toArray('#logobox rect, #logobox path').forEach((rect) => {
        var rectLength = rect.getTotalLength();
        rect.style.strokeDasharray = rectLength;
        rect.style.strokeDashoffset = rectLength;
        timeline.to(rect, {
          strokeDashoffset: 0,
          onComplete: () => {
            rect.style.strokeDasharray = 0;
          },
          onUpdate: () => {
            rect.style.strokeDasharray = rectLength;
          }
        });
      });

      gsap.utils.toArray('#logobox rect').forEach((rect) => {
        timeline.to(rect, { fill: '#ff004f' });
      });

      gsap.utils.toArray(' #logobox path').forEach((rect) => {
        timeline.to(rect, { fill: '#139b5c' });
      });

      gsap.utils.toArray('#logobox rect, #logobox path').forEach((rect) => {
        timeline.to(rect, { strokeWidth: 0 });
      });
    }
    /*var path = document.querySelector('#logobox svg path');
    var pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength + ' ' + pathLength;
    path.style.strokeDashoffset = pathLength;

    // rect
    var rects = document.querySelectorAll('#logobox svg rect');
    for (var rect of rects) {
      var rectLength = rect.getTotalLength();
      rect.style.strokeDasharray = rectLength + ' ' + rectLength;
      rect.style.strokeDashoffset = rectLength;
    }
    var box = document.querySelector('#logo-draw');
    box.addEventListener('scroll', drawscroll);*/
  }, []);
  return (
    <div className="logo-draw" id="logo-draw">
      <div className="draw-box">
        <div className="logobox" id="logobox">
          <Drawing></Drawing>
        </div>
      </div>
    </div>
  );
}

Text.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export default DrawLogo;
