import './Services.scss';
import servicios from '../assets/imagen/services/servicios.svg';

function Services() {
  return (
    <div id="what">
      <div className="services-box">
        <div className="font-serv desktop">
          <img src={servicios} className="serv-img reveal" />
        </div>
        <div className="mobile serv-mobi reveal">SERVICIOS</div>
        <div className="serv-cont">
          <div className="serv-line">
            <h1 className="serv-subtitle reveal ">SOMOS EXPERTOS</h1>
            <hr className="line-services reveal" />
          </div>
          <div className="serv-div reveal">
            <div className="info-serv">
              <h3 className="serv-box reveal">Aplicaciones Móviles</h3>
              <p className="text-div reveal">
                Creamos aplicaciones innovadoras a la medida de iOS y Android diseñadas
                específicamente para tu negocio.
              </p>
            </div>
            <div className="info-serv">
              <h3 className="serv-box reveal">Desarrollo de Software</h3>
              <p className="text-div reveal">
                Desarrollamos, implementamos, creamos y ejecutamos soluciones tecnológicas para
                mejorar el manejo y la eficiencia de las actividades en los distintos sectores de tu
                negocio.
              </p>
            </div>
            <div className="info-serv">
              <h3 className="serv-box reveal">Programación bases de datos</h3>
              <p className="text-div reveal">
                Diseñamos, implementamos y optimizamos tus bases de datos relacionales y no
                relacionales para agilizar los tiempos de carga y mejorar la experiencia de los
                usuarios.
              </p>
            </div>
            <div className="info-serv">
              <h3 className="serv-box reveal">Integración API/WS</h3>
              <p className="text-div reveal">
                Integramos tus herramientas de software con aplicaciones de terceros de acuerdo a
                tus necesidades.
              </p>
            </div>
            <div className="info-serv">
              <h3 className="serv-box reveal">Mantenimiento y soporte técnico </h3>
              <p className="text-div reveal">
                Supervisamos, actualizamos, mejoramos y controlamos el funcionamiento óptimo de tus
                aplicaciones web.
              </p>
            </div>
            <p className="serv-thing mobile reveal">THINK BIG</p>
            <div className="line-home-serv mobile">
              <hr className="line-menu" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
