import './Menu.scss';
import menu4 from '../../../assets/imagen/menu4.jpg';
import menu4Webp from '../../../assets/imagen/menu4.webp';
import justberry from '../../../assets/imagen/justberry.png';
import justberryWebp from '../../../assets/imagen/justberry.webp';
import ImageWebp from '../../../shared/image-webp/ImageWepb';

function Menu() {
  function handleChange(e) {
    if (e.target.checked) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }
  function closeMenu(section) {
    document.getElementById('nav-toggle').checked = false;
    document.querySelector('body').classList.remove('no-scroll');
    setTimeout(() => {
      let element = document.getElementById(section);
      window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
    }, 500);
  }

  return (
    <div className="navigation">
      <input
        type="checkbox"
        className="navigation__checkbox"
        id="nav-toggle"
        onChange={handleChange}
      />
      <label htmlFor="nav-toggle" className="navigation__button menu-animation hover-effect" />

      <div className="navigation__background menu-animation" />
      <ImageWebp alt="logo" className="logo-menu" src={justberry} srcWebp={justberryWebp} />
      <nav className="navigation__nav" role="navigation">
        <div className="menu-container">
          <ImageWebp alt="menu" className="photo-menu" src={menu4} srcWebp={menu4Webp} />
          <ul className="navigation__list">
            <li className="navigation__item">
              <a
                href="#"
                className="navigation__link menu-font hoverable"
                onClick={() => closeMenu('home')}
              >
                HOME
              </a>
            </li>
            <li className="navigation__item">
              <a
                href="#"
                className="navigation__link menu-font hoverable"
                onClick={() => closeMenu('we')}
              >
                NOSOTROS
              </a>
            </li>
            <li className="navigation__item">
              <a
                href="#"
                className="navigation__link menu-font hoverable"
                onClick={() => closeMenu('what')}
              >
                SERVICIOS
              </a>
            </li>
            <li className="navigation__item">
              <a
                href="#"
                className="navigation__link menu-font hoverable"
                onClick={() => closeMenu('contact')}
              >
                CONTACTO
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Menu;
