import './We-new.scss';
import we from '../assets/imagen/team/t7.jpg';
import weWebp from '../assets/imagen/team/t7.webp';
import ImageWebp from '../shared/image-webp/ImageWepb';

function WeNew() {
  return (
    <div>
      <div className="we-box" id="we">
        <div className="we-info">
          <div>
            <div className="title-container">
              <h1 className="we-title">¿QUIÉNES SOMOS? </h1>
              <div>
                <hr className="line-we" />
              </div>
            </div>
            <ImageWebp src={we} srcWebp={weWebp} className="we-pic" />
          </div>
          <div className="we-text-container">
            <h1 className="we-subt reveal">Sobre nosotros.</h1>
            <p className="we-text reveal">
              Somos una empresa de desarrollo de software y soluciones tecnológicas a la medida,
              especializada en ofrecer servicios de TI, con enfoque empresarial. <br /> Estamos
              enfocados en ayudar a nuestros clientes para dar soluciones innovadoras a sus
              necesidades de negocios, a través de herramientas de software. <br /> Contamos con un
              equipo de trabajo especializado en desarrollo de software, dispuesto a atender todo
              tipo de proyecto, pequeño o grande, donde el cliente pueda encontrar el apoyo y
              beneficios de nuestro conocimiento.
              <br />
              El principal valor de nuestra empresa son las personas. Nos preocupamos por la
              comodidad y estabilidad de cada colaborador para que las personas se sientan seguras y
              confiadas. Contamos con un talento comprometido y emprendedor. <br /> Promovemos un
              entorno de trabajo basado en la igualdad de oportunidades, la diversidad y la
              inclusión, e impulsamos formas de trabajo que faciliten el equilibrio entre la vida
              personal y la profesional.
            </p>
            <p className="we-who reveal">TEAM</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeNew;
